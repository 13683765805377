"use strict";

angular.module("informaApp")
    .service("GanttChartStore", function () {
        var data = [];
        var sourceId = null;

        return {
            setActiveSourceId: function (value) {
                sourceId = value;
            },
            getActiveSourceId: function () {
                return sourceId;
            },
            saveSource: function (source) {
                this.removeSource(source.id);

                data.push(source);

                return data.length - 1;
            },
            getSourceById: function (id) {
                var source = _.find(data, function (x) {
                    return x.id == id;
                });

                return _.merge({}, source);
            },
            getAll: function () {
                return _.merge([], data);
            },
            removeSource: function (id) {
                var obj = _.find(data, function (x) {
                    return x.id == id;
                });

                if (obj){
                    data.splice(data.indexOf(obj), 1);
                }

                if (data.length === 0){
                    sourceId = null;
                }
            },
            count: function () {
                return data.length;
            },
            getIdByHash: function (hash) {
                var obj = _.find(data, function (x) {
                    return x.hash == hash;
                });

                return obj == null ? null : obj.id;
            }
        }
    });